import { render, staticRenderFns } from "./ForgottenPasswordDialogForm.vue?vue&type=template&id=36f05d40&scoped=true"
import script from "./ForgottenPasswordDialogForm.vue?vue&type=script&lang=ts"
export * from "./ForgottenPasswordDialogForm.vue?vue&type=script&lang=ts"
import style0 from "./ForgottenPasswordDialogForm.vue?vue&type=style&index=0&id=36f05d40&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36f05d40",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAlert,VBtn,VCol,VContainer,VForm,VIcon,VRow,VTextField})
